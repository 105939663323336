.foot{
    width: 100%;
    height: 40px;
    margin: 101px  0 0 0;
}
.foot__txt{
    text-align: center;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #A9A9A9;
}
.foot__lien{
    text-align: center;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    color: #6d6d6d;
    text-decoration: underline;
}