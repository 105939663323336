

.headTitle{

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 24px;
    &__title{
        font-family: Montserrat;
        font-weight: 700;
        font-size: 18px;
        line-height: 130%;
        color: #333333;
    }
    

    &__txt{
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        flex-direction: row;
        color: #4f4f4f;
    }
}

@media(min-width:1000px){

    .headTitle{
        margin: 61px 0 0 0 ;
    }
}