
*{
  margin: 0;
  padding: 0;
  list-style: none;
}

.app{

  width: calc(100% - 24px);
  padding: 22px 12px;
  
}

@media(min-width:1000px){

  .app{
    width: calc(100% - 13vw);
    max-width: 1920px;
    padding: 46px 6.5vw;
    margin: 0 auto;
  }
}