
@media(max-width:380px){

    .card{

        &__headTxt{
            margin: 0;
            font-family: 'Montserrat', sans-serif;
            max-width: 150px;
            width: 80%;
            font-size: 14px;
            font-weight: 500;
            line-height: 130%;
            color: #828282;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
.card{
    height: 350px;
    width: 100%;
    display: flex;
    flex-direction: column;
    transition: 0.3s;
    cursor: pointer;
    &--none{
        display: none;
        background-color: red;
    }
    &:hover{
        background-color: #4F4F4F10;
        transition: 0.3s;
        border-radius: 26px;
        padding: 6px;
        margin: -6px;
    }
    &:hover >.card__head > .card__txtHost{
        border: 1px solid #EB5757;
        color: #EB5757;
        font-weight: 500;
        transition: 0.3s;

    }
    &__img{
        width: 100%;
        height: 270px;
        border-radius: 24px;
        object-fit: cover;
        background-image: url("../assets/img/appart1.jpg");
    }

    &__head{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin:15px 0 0 0  ;
        width: 100%;
        height: 28px;

    }
    &__headTxt{
        margin: 0;
        font-family: 'Montserrat', sans-serif;
        
        font-size: 14px;
        font-weight: 500;
        line-height: 130%;
        color: #828282;
        
    }
    &__txtHost{
        margin: 0;
        padding: 5px 10px 7px 10px;
        border:1px solid #4f4f4f ;
        border-radius: 12px;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 130%;
        color: #828282;

        &--none{
            display: none;
        }
    }
    &__note{

        width: 64px;
        height: 24px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

    }
    &__iconNote{
        width: 24px;
        height: 100%;

    }
    &__txtIcon{
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 130%;
        color: #4F4F4F;
        margin: 0 0 0 7px;
        padding: 3px 0 0 0 ;
    }
    &__txt{
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        font-weight: 600;
        line-height: 130%;
        color: #333333;
        margin: 12px 0 0 0 ;
        text-align: start;
    }
}


@media(min-width:700px){
.card{
    height: 350px;
    width: 355px;
    display: flex;
    flex-direction: column;


    &__img{
        width: 100%;
        height: 270px;
        border-radius: 24px;
        object-fit: cover;
    }

    &__head{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin:15px 0 0 0  ;
        width: 100%;
        height: 28px;

    }
    &__headTxt{
        margin: 0;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 130%;
        color: #828282;
    }
    &__txtHost{
        margin: 0;
        padding: 5px 10px 7px 10px;
        border:1px solid #4f4f4f ;
        border-radius: 12px;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 130%;
        color: #828282;

        &--none{
            display: none;
        }
    }
    &__note{

        width: 64px;
        height: 24px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

    }
    &__iconNote{
        width: 24px;
        height: 100%;

    }
    &__txtIcon{
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 130%;
        color: #4F4F4F;
        margin: 0 0 0 7px;
        padding: 3px 0 0 0 ;
    }
    &__txt{
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        font-weight: 600;
        line-height: 130%;
        color: #333333;
        margin: 12px 0 0 0 ;
        text-align: start;
    }
}
}
@media(min-width:1000px){
    .card{
    height: 350px;
    width: 395px;
    display: flex;
    flex-direction: column;
    

    &__img{
        width: 100%;
        height: 270px;
        border-radius: 24px;
        object-fit: cover;
    }

    &__head{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin:15px 0 0 0  ;
        width: 100%;
        height: 28px;

    }
    &__headTxt{
        margin: 0;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 130%;
        color: #828282;
    }
    &__txtHost{
        margin: 0;
        padding: 5px 10px 7px 10px;
        border:1px solid #4f4f4f ;
        border-radius: 12px;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 130%;
        color: #828282;

        &--none{
            display: none;
        }
    }
    &__note{

        width: 64px;
        height: 24px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

    }
    &__iconNote{
        width: 24px;
        height: 100%;

    }
    &__txtIcon{
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 130%;
        color: #4F4F4F;
        margin: 0 0 0 7px;
        padding: 3px 0 0 0 ;
    }
    &__txt{
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        font-weight: 600;
        line-height: 130%;
        color: #333333;
        margin: 12px 0 0 0 ;
        text-align: start;
    }
}
}